import React from "react";
import { useStaticQuery, graphql } from "gatsby"

import styled from "@emotion/styled";

import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Paginator from "@components/Navigation/Navigation.Paginator";

import AuthorHero from "@narative/gatsby-theme-novela/src/sections/author/Author.Hero";
import AuthorArticles from "@narative/gatsby-theme-novela/src/sections/author/Author.Articles";

import { Template } from "@types";

const ArticlesPage: Template = ({ location }) => {
  const queryResult = useStaticQuery(graphql`
    query MyQuery {
      sitePage(context: {author: {name: {eq: "Lucas Oliveira"}}}) {
        context {
          group {
            author
            date
            body
            dateForSEO
            excerpt
            id
            secret
            subscription
            timeToRead
            title
            slug
            hero {
              full {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              narrow {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              regular {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              seo {
                src
              }
            }
          }
          pageCount
          pathPrefix
          additionalContext {
            skip
            limit
            originalPath
            author {
              authorsPage
              avatar {
                large {
                  base64
                  sizes
                }
                medium {
                  base64
                  sizes
                }
                small {
                  base64
                  sizes
                }
              }
              bio
              featured
              id
              name
              slug
              social {
                url
              }
            }
          }
          index
          last
          limit
          skip
        }
      }
    }
     `)

  const author = queryResult.sitePage.context.additionalContext.author
  const articles = queryResult.sitePage.context.group
  const pageContext = queryResult.sitePage.context

  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        description={author.bio}
      />
      <Section narrow>
        <AuthorHero author={author} />
        <AuthorArticles articles={articles} />
        <AuthorPaginator show={pageContext.pageCount > 1}>
          <Paginator {...pageContext} />
        </AuthorPaginator>
      </Section>
      <AuthorsGradient />
    </Layout>
  );
}

export default ArticlesPage;

const AuthorsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;

const AuthorPaginator = styled.div`
  text-align: center;
`;
